import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MainChat.css";

// Components
import AgentMessage from "./AgentMessage";
import UserMessage from "./UserMessage";
import ShowAgentMessage from "./ShowAgentMessage";

const MainChat = () => {
  const [allChats, setAllChats] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeValue, setActiveValue] = useState(null);
  const [number, setNumber] = useState("");

  // States Used to send to api
  const [age, setAge] = useState("");
  const [medicare, setMedicare] = useState("");
  const [zipcode, addZipCode] = useState("");

  const setZipcode = (zip) =>{
    if(zip.length < 6){
      addZipCode(zip)
    }
  }

  const callApi = async () => {
    try{
      const response = await axios.get(`https://api.zippopotam.us/us/${zipcode}`);
      const result = response.data?.places[0];
      window._rgba_tags.push({ zip: zipcode });
      window._rgba_tags.push({ city: result["place name"] });
      window._rgba_tags.push({ state: result["state"] });
    }catch(err){
      console.log(err);
    } finally {
      window._rgba_tags.push({ age: age === "Yes" ? "55 - +" : "- - 55" });
      window._rgba_tags.push({
        "medicare-medicaid": medicare === "Yes" ? "Yes" : "No",
      });
    }
  };

  useEffect(() => {
    if (activeStep === 5) {
      callApi();
    }
  }, [activeStep]);

  useEffect(() => {
    function GetNumber(number, tagId, firstTime) {
      window.pnumber = number;
      setNumber(number);
    }
    (function (e, d) {
      var ringba_com_tag = "JSbd3b63815f9648d2b79edbb585c27496"; // ringba id
      var _sc = d.getElementsByTagName("script");
      var _s = _sc[_sc.length - 1];
      e._rgba = e._rgba || { q: [] };
      e._rgba.q.push({
        tag: ringba_com_tag,
        cb: GetNumber,
        render: false,
        script: _s,
      });
      if (!(e._rgba.loading = !!e._rgba.loading)) {
        var sc = d.createElement("script");
        sc.id = "ringba_script_id"; // any id
        sc.type = "text/javascript";
        sc.async = true;
        sc.src = "//js.callcdn.com/js_v3/min/ringba.com.js";
        var s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(sc, s);
        e._rgba.loading = true;
      }
    })(window, document);
    window._rgba_tags = window._rgba_tags || [];
  }, []);
  const renderMessages = () => {
    return (
      <AgentMessage
        number={number}
        setAge={setAge}
        setMedicare={setMedicare}
        setZipcode={setZipcode}
        zipCodeValue={zipcode}
        setAllChats={setAllChats}
        activeValue={activeValue}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setActiveValue={setActiveValue}
      />
    );
  };

  return (
    <div className="max-w-md mx-auto bg-white">
      <div className="p-4">
        <div className="space-y-4">
          <div className="chat-container pb-2">
            {allChats.map((chat, index) => {
              const { type, values } = chat;
              return type === "agent" ? (
                <ShowAgentMessage messages={values} key={index} />
              ) : (
                <UserMessage activeValue={values} key={index} />
              );
            })}
            {renderMessages()}
            {/* {activeValue === "Medicare" && (
              <div id="disconnected" className="pt-2">
                <div className="relative flex py-5 items-center">
                  <div className="flex-grow border-t border-gray-200"></div>
                  <span className="flex-shrink mx-4 text-gray-300 text-xs">
                    Chat Closed
                  </span>
                  <div className="flex-grow border-t border-gray-200"></div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainChat;
