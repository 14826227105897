// Here need to pass something in last value of values bcz in design we are showing last button to handle this so when we are passing this to all chats we are slicing from last in agentmessage component
export const chats = (selectedOption) => [
  {
    values: [
      "Hi 👋",
      "I'm Hannah from Health Benefits.",
      "Want to find out if you qualify for up a $6,400 subsidy as a Health Credit? Tap Yes! 😃",
      "Testing"
    ],
  },
  {
    values: [
      "Okay, let me ask you two quick questions.",
      "Testing"
    ],
  },
  {
    values: [
      "Are you above 55 or not",
      "Testing",
    ]
  },
  {
    values: [
      "Please Enter Your Zip Code",
      "Testing",
    ]
  },
  {
    values: ["Are you on medicaid or medicare?", "Testing",],
  },
  {
    values:
      // (selectedOption === "No" && [
      //   "🎉 Congratulations! 🎁",
      //   "You're pre-qualified for a $1,400 in Tax Credits.",
      //   "You can use it to pay for your medical expenses and provide you cash relief for rent, bills, groceries, etc…",
      //   "Tap the number button below to call now to get your $6,400 Health Credit, It only takes 2 minutes.",
      //   "Testing"
      // ]) ||
      (selectedOption === "No" && [
        "🎉 Congratulations! 🎁",
        "You're pre-qualified for a subsidy as a Health Credit.",
        "You can use it to pay for your medical expenses and provide you cash relief for rent, bills, groceries, etc…",
        "Tap the number button below to call now to get your Health Credit, It only takes 2 minutes.",
        "Testing"
      ]) ||
      (selectedOption === "Yes" && [
        "Sorry, but the questions you answered have helped us determine that we are not able to help you at this time.",
        "Have a nice day! 😃",
        "Testing"
      ]),
  },
];

export const buttonKeys = [
  {
    values: ["Yes"],
  },
  {
    values: ["Yes"],
  },
  {
    values: ["Yes", "No"],
  },
  {
    values: ["Submit"],
  },
  {
    // values: ["No", "Medicaid", "Medicare"],
    values: ["Yes", "No"],
  },
  {
    values: ["Phone Number"], // Not Show this for medicare option
  },
];
