import React, { useState, useRef, useEffect } from "react";
import { chats, buttonKeys } from "../../data";
import AgentImg from "../../assets/hannah.jpeg";

const AgentMessage = ({
  number,
  activeValue,
  activeStep,
  setAge,
  setMedicare,
  setZipcode,
  setActiveStep,
  setActiveValue,
  setAllChats,
  hide,
  zipCodeValue
}) => {
  const messageList = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  let response = chats(activeValue);
  let messages = response[activeStep].values;
  let buttons = buttonKeys[activeStep].values;

  const handleClick = (e, text) => {
    e.preventDefault();
    setCurrentIndex(0);
    if (activeStep === 2) {
      setAge(text);
    }
    if (activeStep === 4) {
      setMedicare(text);
    }
    setActiveStep(activeStep + 1);
    setActiveValue(text);
    const newObject1 = { type: "agent", values: messages.slice(0, -1) };
    const newObject2 = { type: "user", values: text };
    setAllChats((prevChats) => [...prevChats, newObject1, newObject2]);
  };
  useEffect(() => {
    messageList.current?.scrollIntoView({
      block: "end",
    });
    const interval = setInterval(() => {
      if (currentIndex < messages.length) {
        setCurrentIndex(currentIndex + 1);
      } else {
        clearInterval(interval); // Stop the interval when all strings are displayed
      }
    }, 1500);

    return () => {
      clearInterval(interval); // Cleanup when the component unmounts
    };
  }, [currentIndex, messages]);

  // useEffect(() => {
  //   messageList.current?.scrollIntoView({
  //     block: "start",
  //   });
  // }, [allChats]);

  return (
    <div id="agentBlock" className="flex items-end w-5/6">
      <div className="flex-shrink-0">
        <img className="w-8 h-8 rounded-full" src={AgentImg} alt="Sender Avatar" />
      </div>
      <div className="ml-3 agent-chat">
        {messages.slice(0, currentIndex + 1).map((message, idx) => {
          return (
            <React.Fragment key={idx}>
              {idx === messages.length - 1 ? (
                !hide &&
                (activeStep !== 5 || activeValue === "No") && (
                  <>
                    <div
                    key={idx}
                      id="button"
                      className="bg-gray-200 p-3 rounded-lg shadow-xs mt-2 w-fit"
                    >
                      <div className="flex flex-col">
                        {buttons.map((button, idx) => {
                          return activeStep === 5 ? (
                            <button
                              key={idx}
                              className="chat-button text-white font-bold bg-blue-500 rounded-full py-3 px-8 mt-2"
                              type="button"
                              data-form-step="2"
                              data-form-value="Yes"
                              id={idx}
                            >
                              <a
                                className="number"
                                href={`tel:${
                                  number === "" ? "18774042321" : number
                                }`}
                              >
                                {number === "" ? "1 (877) 404-2321" : number}
                              </a>
                            </button>
                          ) : (
                            <button
                            key={idx}
                              className="chat-button text-white font-bold bg-blue-500 rounded-full py-3 px-8 mt-2"
                              type="button"
                              data-form-step="2"
                              data-form-value="Yes"
                              id={idx}
                              onClick={(e) => handleClick(e, button)}
                            >
                              {button}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )
              ) : (
                <div
                  id={idx}
                  key={idx}
                  className="bg-gray-200 p-3 rounded-lg shadow-xs mt-2 w-fit"
                >
                  {idx <= currentIndex - 1 ? (
                    activeStep === 3 ? (
                      <>
                        <p className="text-md text-gray-800">{message}</p>
                        <p className="text-md text-gray-800 mt-2">
                          <input
                            className="w-full p-2 appearance-none"
                            type="number"
                            value={zipCodeValue}
                            onChange={(e) => setZipcode(e.target.value)}
                          />
                        </p>
                      </>
                    ) : (
                      <p className="text-md text-gray-800">{message}</p>
                    )
                  ) : (
                    <p className="text-md text-gray-800">
                      <span className="relative h-4 w-4 inline-flex">
                        <span className="animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full opacity-75 bg-gray-500"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-400"></span>
                      </span>
                      <span className="relative h-4 w-4 inline-flex">
                        <span className="animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full opacity-75 bg-gray-500"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-400"></span>
                      </span>
                      <span className="relative h-4 w-4 inline-flex">
                        <span className="animate-ping absolute inline-flex h-[8px] w-[8px] rounded-full opacity-75 bg-gray-500"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-400"></span>
                      </span>
                    </p>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div ref={messageList} />
    </div>
  );
};

export default AgentMessage;
