import React from "react";
import "./Top.css";

// Components
import Header from "./Header";
import MainChat from "./MainChat";

const Top = () => {
  return (
    <main className="min-h-screen">
      <Header />
      <MainChat />
    </main>
  );
};

export default Top;
