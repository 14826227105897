import "./App.css";

// Components
import Top from "./components/top/Top";
import Footer from "./components/bottom/Footer";

function App() {
  return (
    <div className="App">
      <Top />
      <Footer />
    </div>
  );
}

export default App;
