import React from "react";
import AgentImg from '../../assets/hannah.jpeg';

const ShowAgentMessage = ({
  messages
}) => {
  return (
    <div id="agentBlock" className="flex items-end w-5/6">
      <div className="flex-shrink-0">
        <img
          className="w-8 h-8 rounded-full"
          src={AgentImg}
          alt="Sender Avatar"
        />
      </div>
      <div className="ml-3 agent-chat">
        {messages.map((message, idx) => {
          return (
            <div
              id={idx}
              key={idx}
              className="bg-gray-200 p-3 rounded-lg shadow-xs mt-2 w-fit"
            >
              <p className="text-md text-gray-800">{message}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShowAgentMessage;
