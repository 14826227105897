import React from "react";
import ProfileImg from '../../assets/profile.png';

const UserMessage = ({activeValue}) => {
  return (
    <div id="userBlock" className="flex items-start justify-end pt-4">
      <div className="mr-3 receiver-chat">
        <div
          id="msg5"
          className="bg-blue-500 text-white p-3 rounded-lg shadow-xs mt-2 inline-block"
        >
          <p className="text-md">{activeValue}</p>
        </div>
      </div>
      <div className="flex-shrink-0">
        <img
          className="w-8 h-8 rounded-full"
          src={ProfileImg}
          alt="Receiver Avatar"
        />
      </div>
    </div>
  );
};

export default UserMessage;
