import React from "react";
import "./Header.css";

const Header = () => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const dayOfWeek = today.getDay();
  const dayName = daysOfWeek[dayOfWeek];
  const dd = String(today.getDate()).padStart(2, "0"); // Get day and pad with leading zero if necessary
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Get month (January is 0, so add 1) and pad with leading zero if necessary
  const yyyy = today.getFullYear(); // Get full year
  const formattedDate = mm + "/" + dd + "/" + yyyy;
  return (
    <div className="max-w-4xl mx-auto bg-white text-center text-gray-600">
      <div className="p-4">
        <p className="font-serif text-md sm:text-lg font-bold mt-2">
          <span className="text-red-600">Warnings:</span> Enrollment closes on{" "}
          {dayName}, {formattedDate} at midnight.
        </p>
        <h2 className="font-bold text-xl sm:text-3xl mt-1">
          Unlock <span className="text-green-600">FREE $6400</span> Subsidy For Groceries, Rent, Gas and Everyday Needs.
          Save More For Your Future
        </h2>
      </div>
      <div className="w-full md:col-span-1 text-center block">
        <div className=" text-sm flex items-center justify-center">
          <span className="relative h-4 w-4 inline-flex mr-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 bg-green-500"></span>
            <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
          </span>
          Hannah is Online.
        </div>
      </div>
    </div>
  );
};

export default Header;
